import LazyLoad from "vanilla-lazyload/dist/lazyload.esm.js";


function  lazyload() {
    window.lazyLoadInstance = new LazyLoad({
        elements_selector: ".js-lazy",
    });
}

lazyload();

document.addEventListener('LokiRequestEnd', function () {
    lazyload();
});
