import { gsap } from "gsap";
import { ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animations = {
    init() {
        let self = this;
        if(document.querySelectorAll('.js-fade-in-bottom').length > 0) {
            self._fadeInBottom();
        }

        if(document.querySelectorAll('.js-fade-in-right').length > 0) {
            self._fadeInRight();
        }

        if(document.querySelectorAll('.js-img-reveal').length > 0) {
            self._imgReveal();
        }

        if(document.querySelectorAll('.js-fade-in-bottom-single').length > 0) {
            self._singleItem();
        }

        if(document.querySelectorAll('.js-move-img').length > 0) {
            self._moveImg();
        }
    },

    _fadeInBottom() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-bottom');

        if(window.innerWidth > 768) {
            items.forEach(item => {
                gsap.to(item.querySelectorAll('.js-anim-item'), {
                    scrollTrigger: {
                        trigger: item,
                        start: 'top center+=15%',
                        end: 'bottom bottom',
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,
                });
            });
        }   
        else {
            items.forEach(item => {
                item.querySelectorAll('.js-anim-item').forEach(elem => {
                    gsap.to(elem, {
                        scrollTrigger: {
                            trigger: elem,
                            start: 'top center+=25%',
                            end: 'bottom bottom',
                        },
                
                        y: 0,
                        autoAlpha: 1,
                        duration: 0.5,
                        ease: 'power4.easeOut',
                        stagger: 0.1,
                    });
                });
                
            });
        }
        
        
    },

    _fadeInRight() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-right');
        
        items.forEach(item => {
            gsap.to(item.querySelectorAll('.js-anim-item'), {
                scrollTrigger: {
                    trigger: item,
                    start: 'top center+=15%',
                    end: 'bottom bottom',
                },
        
                x: 0,
                autoAlpha: 1,
                duration: 0.5,
                ease: 'power4.easeOut',
                stagger: 0.1,
            });
        });
    },

    _imgReveal() {
        let self = this;
        
        let items = document.querySelectorAll('.js-img-reveal');

        items.forEach( item => {
            let image = item.querySelector('img');

            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    start: 'top center+=15%',
                    end: 'bottom bottom',
                }
            });

            tl.set(item, {autoAlpha: 1});

            tl.from(item, 1, {
                xPercent: -100,
                ease: 'power2.out'
            });
            tl.from(image, 1, {
                xPercent: 100,
                
                delay: -1,
                ease: 'power2.out'
            });
        })
    },

    _singleItem() {
        let items = document.querySelectorAll('.js-fade-in-bottom-single');
        items.forEach(item => {
            item.querySelectorAll('.js-anim-item').forEach(elem => {
                gsap.to(elem, {
                    scrollTrigger: {
                        trigger: elem,
                        start: 'top center+=25%',
                        end: 'bottom bottom',
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,
                });
            });
            
        });
    },

    _moveImg() {
        gsap.from(".js-move-img .js-anim-item", {
            yPercent: 10,
            ease: "power2.out",
            duration: 2,
            scrollTrigger: {
              trigger: ".js-move-img",
              start: "top center+=25%", // when the center of the trigger hits 40% from the top of the viewport
              end: "bottom-=25% center+=25%", // end after scrolling 1000px beyond the start
              scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            }
          });
    }
};

animations.init();